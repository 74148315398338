import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';
import { membershipStatusLoaded, thankYouPageCtaButtonClick } from '@wix/bi-logger-membership/v2';
import { ControllerFlowAPI, ControllerParams } from '@wix/yoshi-flow-editor';
import { OrdersApi, PlansApi } from '../../../services';
import { toMembershipStatus } from '../../../services/bi';
import { CheckoutData, StatusData, StatusProps } from '../../../types/common';
import { PackagePickerInteractions } from '../../../types/PackagePickerFedops';
import { getPageType } from '../../../utils/pageType';
import { Router } from './Router';

export class StatusController {
  constructor(
    protected setProps: (props: Partial<StatusProps>) => void,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected ordersApi: OrdersApi,
    protected flowAPI: ControllerFlowAPI,
    protected router: Router,
    protected plansApi: PlansApi,
  ) {}

  public async initialize(statusData: StatusData) {
    return this.update(statusData);
  }

  public async update(statusData: StatusData, plan?: PublicPlan) {
    this.flowAPI.fedops.interactionStarted(PackagePickerInteractions.StatusPageLoaded);
    this.flowAPI.bi?.report(
      membershipStatusLoaded({
        membershipStatus: toMembershipStatus(
          getPageType(statusData, statusData.purchaseData.checkoutData.integrationData),
        ),
      }),
    );
    return this.setProps({
      successful: statusData.successful,
      translatedError: statusData.error,
      startDate: statusData.startDate,
      navigateBackToTPA: () => this.navigateBackToTPA(statusData),
      navigateBackToCheckout: () => this.navigateBackToCheckout(statusData.purchaseData.checkoutData, plan),
      biThankYouPageCtaButtonClick: () => this.flowAPI.bi?.report(thankYouPageCtaButtonClick({ buttonName: 'null' })),
    });
  }

  navigateBackToTPA = async ({ planName, startDate, purchaseData }: StatusData) => {
    const { navigateToPageProps, navigateToSectionProps } = purchaseData.checkoutData.integrationData;

    if (navigateToSectionProps) {
      if (startDate) {
        navigateToSectionProps.queryParams = {
          ...(navigateToSectionProps.queryParams ?? {}),
          startDate,
          planName,
        };
      }

      const { queryParams, state, ...sectionProps } = navigateToSectionProps;
      const { relativeUrl } = await this.wixCodeApi.site.getSectionUrl(sectionProps);

      let url = relativeUrl + '';
      if (state) {
        url += '/' + state;
      }
      if (queryParams) {
        url += '?appSectionParams=' + encodeURIComponent(JSON.stringify(queryParams));
      }
      this.wixCodeApi.location.to!(url);
    } else if (navigateToPageProps) {
      this.wixCodeApi.location.navigateTo?.({ pageId: navigateToPageProps });
    }
  };

  navigateBackToCheckout = async ({ integrationData, planId }: CheckoutData, plan?: PublicPlan) => {
    let plans: PublicPlan[] = [];
    if (plan) {
      plans = [plan];
    } else {
      try {
        plans = await this.plansApi.loadPaidPlans({ planIds: [planId] });
      } catch {}
    }
    if (plans.length > 0) {
      this.router.gotoCheckout(plans[0], integrationData);
    } else {
      this.router.gotoHomePage();
    }
  };
}
